<template>
    <div>
        <Loading
            v-if="!permissionsLoaded"
            active
            loader=""
            class="br-loading-component"
        >
            <FontAwesomeIcon
                icon="spinner"
                size="5x"
                pulse
                class="mb-2"
            />

            <template #after>
                <h3
                    class="text-muted"
                >
                    loading...
                </h3>
            </template>
        </Loading>

        <div
            v-else-if="hasAccess"
            id="app"
            class="container-fluid"
        >
            <div class="row">
                <div class="col-auto px-0">
                    <AMMenu />
                </div>

                <div class="col px-0 d-flex flex-column">
                    <div class="container-fluid">
                        <AMInAppAlerts data-cy="inAppAlerts" />
                    </div>

                    <AMHeader :title="pageTitle">
                        <template slot="header-items">
                            <BTooltip
                                custom-class="header-tooltip"
                                placement="left"
                                title="Application Configuration"
                                target="configurationCog"
                                boundary-padding="0"
                            />

                            <li class="nav-item py-2">
                                <RouterLink
                                    v-if="userHasConfigurationPermission"
                                    id="configurationCog"
                                    :to="{name: 'admin.configurations'}"
                                    data-cy="applicationConfigurationCog"
                                >
                                    <FontAwesomeIcon
                                        icon="cog"
                                        class="fa fa-fw mx-1 fa-2x extra-item align-self-center"
                                    />
                                </RouterLink>
                            </li>
                        </template>
                    </AMHeader>

                    <RouterView />

                    <div class="row footer m-0 mt-auto">
                        <div class="col-4">
                            <a
                                href="https://ticket.imtins.com/status/endpoints"
                                class="btn btn-link p-0"
                            >
                                Platform Status
                            </a>
                        </div>

                        <div class="col text-muted text-center">
                            <span class="copyright">&copy;{{ copyrightText }}</span>
                        </div>

                        <div class="col-4 text-right">
                            <button
                                v-scroll-to="'#app'"
                                class="btn btn-link btn-back-to-top p-0"
                                @click="$ga.event('App', 'Back To Top', 'action')"
                            >
                                <FontAwesomeIcon
                                    icon="caret-up"
                                />
                                Back to top
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Forbidden v-else />
    </div>
</template>

<script>
    import AMHeader from '@imt/vue-admin-menus/src/components/AMHeader.vue';
    import AMInAppAlerts from '@imt/vue-admin-menus/src/components/AMInAppAlerts.vue';
    import AMMenu from '@imt/vue-admin-menus/src/components/AMMenu.vue';
    import authMixin from '@imt/vue-toolbox/src/mixins/auth';
    import dayjs from 'dayjs';
    import {mapMutations} from 'vuex';

    import permissionsMixin from '@/mixins/permissions';


    export default {
        name: 'App',
        components: {
            Forbidden: () => import(/* webpackChunkName: "KCForbidden" */ '@imt/vue-kit-car/src/components/errors/Forbidden.vue'),
            Loading: () => import('vue-loading-overlay'),
            AMHeader,
            AMInAppAlerts,
            AMMenu
        },
        mixins: [
            authMixin,
            permissionsMixin,
        ],
        computed: {
            copyrightText() {
                return `Copyright ${dayjs().year()} IMT Insurance. All rights reserved.`;
            },
            pageTitle() {
                return this.$route.meta?.title || 'Business Rules';
            },
            userHasConfigurationPermission() {
                return this.userCan('configuration_access');
            }
        },
        mounted() {
            this.$root.$on('bv::popover::shown', $evt => {
                if ($evt.relatedTarget.id === 'toolTipContainer') {
                    this.$ga.event('Popover', $evt.relatedTarget.innerText, 'error popover');
                } else {
                    this.$ga.event('Popover', $evt.target.id, 'help hover');
                }
            });

            this.$root.$on('bv::tooltip::shown', $evt => {
                this.$ga.event('Tooltip', $evt.vueTarget.title, 'help hover', $evt.target.classList);
            });

            if (this.$route.query.page_id && this.$route.query.page_type) {
                this.SET_SEARCH_FIELDS({
                    key: 'pageId',
                    value: this.$route.query.page_id,
                });

                this.SET_SEARCH_FIELDS({
                    key: 'pageType',
                    value: this.$route.query.page_type,
                });

                this.SET_SEARCH_FIELDS({
                    key: 'ruleType',
                    value: 'page_display,page_validation',
                });
            }
        },
        methods: {
            ...mapMutations([
                'SET_SEARCH_FIELDS'
            ]),
        },
    };
</script>

<style lang="sass">
    @import "../sass/business-rules.sass"

    .br-loading-component
        width: 100vw
        height: 100vh
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        font-family: Arial, Helvetica, sans-serif

        .vld-icon
            display: flex
            justify-content: center
            align-items: center
            flex-direction: column
</style>
