import {pathValidator} from '@imt/vue-spotme/src/plugins/dot-notation';
import uniqBy from 'lodash/uniqBy';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {extend} from 'vee-validate';
import {required, max, regex} from 'vee-validate/dist/rules';
import Vue from 'vue';

extend('policy_data_path', pathValidator);
extend('max', {
    ...max,
    params: ['length'],
    message: 'This field must be less than {length} characters',
});

extend('required', {
    ...required,
    message: 'This field is required',
});

extend('regex', {
    ...regex,
    message: 'Field is not in the valid format',
});

extend(
    'unique',
    {
        message: 'Each value must be unique.',
        validate(items) {
            let uniqueItems = uniqBy(items, value => {
                return value.value.trim();
            });

            return uniqueItems.length === items.length;
        },
        immediate: false,
    },
);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
