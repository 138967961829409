import {library} from '@fortawesome/fontawesome-svg-core';
import {faClone} from '@fortawesome/pro-regular-svg-icons';
import {
    faBuilding,
    faCaretUp,
    faCog,
    faEye,
    faInfoCircle,
    faMinusCircle,
    faPencilAlt,
    faPlus,
    faPlusCircle,
    faSave,
    faSearch,
    faSpinner,
    faSquareTerminal,
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
    faBuilding,
    faCaretUp,
    faClone,
    faCog,
    faEye,
    faInfoCircle,
    faMinusCircle,
    faPencilAlt,
    faPlus,
    faPlusCircle,
    faSave,
    faSearch,
    faSpinner,
    faSquareTerminal,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
