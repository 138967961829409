export var helpWording = {
    en: {
        'rules': {
            'adding': {
                'actions': 'Determines how this rule communicates to the user.',
                'canAccommodate': 'Accommodations can annul this rule.',
                'description': 'Brief description of the function of this rule.',
                'policyEffectiveDate': 'Determines the date when this rule comes into effect based on the policy effective date.',
                'helpWordingText': 'Wording to help users.',
                'internalNotes': 'Notes for internal employees.',
                'pageType': 'The page type that this rule is made for.',
                'underwritingFix': 'Requires underwriter to make corrections if the condition is made.',
                'ruleConditions': 'These conditions make up what the rule will do inside the policy.',
                'ruleType': 'Determines what the rule is is meant to be used for.',
                'objectType': 'Path to a policy data resource.',
                'set': 'Specifies which set this rule is related to.',
                'task': 'Specifies which task this rule should be added as a Related Item to.',
                'title': 'Rule name',
                'transactionType': 'Determines when the rule is used based on the policy transaction type.',
            },
        },
    },
};

export var tooltips = {
    en: {
        'actions': {
            'create': 'Add New',
            'cloneRule': 'Clone Rule',
            'createDraft': 'Create a New Draft',
            'delete': 'Delete',
            'disableRule': 'Disable Rule',
            'deleteRule': 'Delete Rule',
            'duplicate': 'Duplicate',
            'editVersion': 'Edit Version',
            'enableRule': 'Re-Enable Rule',
            'obsoleteRule': 'Obsolete Rule',
            'options': 'Options',
            'publish': 'Publish',
            'revert': 'Revert',
            'schedule': 'Schedule',
            'search': 'Search',
            'unschedule': 'Unschedule',
            'unscheduleRevert': 'Unschedule this revert',
            'viewRule': 'View Rule',
        },
        'actionTypes': {
            'agent_remark': 'Agent Remark',
            'roll_off_class_code': 'Class Code Roll-Off',
            'roll_on_class_code': 'Class Code Roll-On',
            'roll_off_form': 'Form Roll-Off',
            'roll_on_form': 'Form Roll-On',
            'screen_error': 'Screen Error',
            'set_value': 'Set a value for a targeted field',
            'show_component': 'Show a Web Form Component',
            'hide_component': 'Hide a Web Form Component',
            'uassist_error': 'U-Assist Error',
            'uassist_info': 'U-Assist Informational Message',
            'uassist_warning': 'U-Assist Warning',
        },
        'actionTypeOrder': [
            'agent_remark',
            'roll_on_class_code',
            'roll_off_class_code',
            'roll_on_form',
            'roll_off_form',
            'show_component',
            'hide_component',
            'screen_error',
            'uassist_error',
            'uassist_info',
            'uassist_warning',
        ],
        'pageTypes': {
            'class_code': 'Class Code',
            'form': 'Form',
            'page': 'CMS Page',
        },
        'pageTypeOrder': ['page', 'form', 'class_code'],
        'ruleTypes': {
            'clean_up': 'Clean Up',
            'cross_validation': 'Cross Validation',
            'page_validation': 'Page Validation',
            'page_display': 'Page Display',
        },
        'ruleTypeOrder': ['cross_validation', 'page_validation', 'page_display', 'clean_up'],
        'searchFieldNames': {
            'componentIdentifier': 'Component Identifier',
            'dataType': 'Data Type',
            'description': 'Description',
            'policyEffectiveDate__gte': 'Policy Effective Date After',
            'policyEffectiveDate__lte': 'Policy Effective Date Before',
            'helpWordingText': 'Help Wording',
            'internalNotes': 'Internal Notes',
            'pageId': 'Title',
            'pageType': 'Page Type',
            'ruleType': 'Rule Type',
            'status': 'Status',
            'search': 'Text Search',
            'title': 'Title',
            'transactionType': 'Transaction Type',
            'underwritingFix': 'Requires Underwriting Fix',
            'actionType': 'Actions',
        },
        'transactionTypes': {
            'amendment': 'Amendment',
            'renewal': 'Renewal',
            'quote': 'Quote',
        },
        'transactionTypeOrder': ['quote', 'amendment', 'renewal'],
        'versioning': {
            'disabled': 'Disabled',
            'draft': 'Draft',
            'not_published': 'Not yet published',
            'obsolete': 'Obsolete',
            'published': 'Published',
            'published_with_draft': 'Published and has a draft',
            'conflict': 'Has Conflict',
            'reverted': 'Reverted',
            'scheduled': 'Scheduled to be published',
            'scheduled_for_revert': 'Scheduled to be reverted',
        },
        'versioningOrder': [
            'not_published',
            'scheduled',
            'published',
            'published_with_draft',
            'conflict',
            'obsolete',
            'disabled',
        ],
    },
};

export const mergeTableReadableText = {
    internalNotes: 'Internal Notes',
    helpWordingText: 'Help Wording',
    effectiveDate: 'Effective Date',
    componentType: 'Component Type',
    name: 'Name',
    position: 'Position',
    actions: 'Actions',
    setId: 'Set',
    taskId: 'Task',
    actionType: 'Action Type',
    category: 'Category',
    condition: 'Condition',
    description: 'Description',
    expressionGroups: 'Expression Groups',
    transactionType: 'Transaction Type',
    data: 'Data',
    path: 'Path',
    value: 'Value',
    dataType: 'Data Type',
    errorMessage: 'Error Message',
    form: 'Form',
    children: 'Children',
    expressions: 'Expressions',
    operator: 'Operator',
    components: 'Components',
    target: 'Target',
};
