import {mapState} from 'vuex';

export default {
    computed: {
        canDisable() {
            return !!this.permissions.rule_disable;
        },
        canManage() {
            return !!this.permissions.rule_manage;
        },
        canVersionManage() {
            return !!this.permissions.versioning_manage;
        },
        hasAccess() {
            return !!this.permissions.business_rules_access;
        },
        permissionsLoaded() {
            return !!this.user.id;
        },
        ...mapState('toolbox', [
            'user',
            'permissions',
        ]),
    }
};
