import {UUID_REGEX} from '@imt/vue-toolbox/src/utils/index';

export default [
    {
        path: '/',
        redirect: {
            name: 'admin',
        },
    },
    {
        path: '/admin',
        children: [
            {
                path: '',
                name: 'admin',
                redirect: {
                    name: 'admin.rules',
                },
            },
            {
                path: 'rules',
                name: 'admin.rules',
                component: () => import(/* webpackChunkName: "BRRules" */ '@/views/BRRules.vue'),
            },
            {
                path: `policy-system/:policySystemId(${UUID_REGEX})/rules`,
                component: () => import(/* webpackChunkName: "BRRules" */ '@/views/BRRules.vue'),
                children: [
                    {
                        path: '',
                        name: 'admin.rules.list',
                        component: () => import(/* webpackChunkName: "BRRuleList" */ '@/views/BRRuleList.vue'),
                        children: [
                            {
                                path: `:ruleId(${UUID_REGEX})`,
                                name: 'admin.rules.options',
                                component: () => import(/* webpackChunkName: "BRRulesOptions" */ '@/components/BRRuleOptions.vue'),
                            },
                            {
                                path: 'search',
                                name: 'admin.rules.search',
                                component: () => import(/* webpackChunkName: "BRAdvancedSearchModal" */ '@/components/BRAdvancedSearchModal.vue'),
                            },
                            {
                                path: 'test',
                                name: 'admin.rules.test',
                                component: () => import(/* webpackChunkName: "BRAdvancedSearchModal" */ '@/components/BRTestModeModal.vue'),
                            },
                        ],
                    },
                    {
                        path: `:ruleId(${UUID_REGEX})/merge`,
                        name: 'merge',
                        component: () => import(/* webpackChunkName: "BRMerge" */ '@/views/BRMerge.vue'),
                    },
                    {
                        path: 'new',
                        name: 'admin.rules.builder.add',
                        component: () => import(/* webpackChunkName: "BRRuleBuilder" */ '@/views/BRRuleBuilder.vue'),
                    },
                    {
                        path: `:ruleId(${UUID_REGEX})/edit/:versionId(${UUID_REGEX})`,
                        name: 'admin.rules.builder.edit',
                        component: () => import(/* webpackChunkName: "BRRuleBuilder" */ '@/views/BRRuleBuilder.vue'),
                    },
                    {
                        path: `:ruleId(${UUID_REGEX})/view/:versionId(${UUID_REGEX})`,
                        name: 'admin.rules.builder.view',
                        component: () => import(/* webpackChunkName: "BRRuleBuilder" */ '@/views/BRRuleBuilder.vue'),
                        children: [
                            {
                                path: 'test',
                                name: 'admin.rules.builder.test',
                                component: () => import(/* webpackChunkName: "BRAdvancedSearchModal" */ '@/components/BRTestModeModal.vue'),
                            },
                        ]
                    },
                ],
            },
            {
                path: 'configurations',
                name: 'admin.configurations',
                component: () => import(/* webpackChunkName: "BRConfigurations" */ './views/BRConfigurations.vue'),
                meta: {
                    title: 'Application Configuration',
                },
            },
        ],
        component: () => import(/* webpackChunkName: "BRAdmin" */ '@/views/BRAdmin.vue'),
    },
    // Fallback
    {
        path: '*',
        name: 'not-found',
        meta: {
            title: 'Not Found',
        },
        component: () => import(/* webpackChunkName: "KCNotFound" */ '@imt/vue-kit-car/src/components/errors/NotFound.vue'),
    },
];
