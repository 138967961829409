import BootstrapVue from 'bootstrap-vue';
import VueMask from 'v-mask';
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/plugins/analytics';
import '@/plugins/font-awesome';
import '@/plugins/sentry';
import '@/plugins/vee-validate';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueScrollTo);
Vue.use(VueMask);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
