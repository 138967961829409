import vueVersioning from '@imt/vue-versioning/src/mixins/versioning';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import lowerCase from 'lodash/lowerCase';
import orderBy from 'lodash/orderBy';
import remove from 'lodash/remove';
import uniqid from 'uniqid';

dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

export var humanReadableRule = {
    config: {
        processedSyncIds: {
            // This is used in scenarios where groups/components have children so we don't process the same one twice
            groups: [],
            expressions: [],
            components: [],
            reset() {
                this.groups = [];
                this.expressions = [];
                this.components = [];
            },
        },
        componentType: {
            condition: {
                humanReadable: data => {
                    return data.dataType ? `${lowerCase(data.dataType)} ` : '';
                },
            },
            literal: {
                humanReadable: data => {
                    return data.dataType !== 'list' ?
                        `"${data.value}" ` :
                        data.dataType ? `"${data.value.join('", "')}" ` : '';
                },
            },
            object: {
                humanReadable: data => {
                    let objectTypePath = data.dataType || '';

                    if (objectTypePath.includes('version') && data.version) {
                        objectTypePath = objectTypePath.replace('version', data.version);
                    }

                    return `"${objectTypePath}"`;
                },
            },
            method: {
                humanReadable: data => {
                    return data.dataType !== 'math' && data.dataType ? `${data.dataType} ` : '';
                },
            },
            operator: {
                humanReadable: data => {
                    return data.dataType ? `${lowerCase(dropdownUtils.constants.mathematicalMethodTypes[data.dataType])} ` : '';
                },
            },
        },
    },
    async createHumanReadable(version = {}) {
        // Make sure processedSyncIds are reset before creating human readable rule
        this.config.processedSyncIds.reset();

        return this.humanReadableGroups(version.expressionGroups);
    },
    async humanReadableGroups(groups = [], isChild = false) {
        let humanReadable = '',
            filteredGroups = isChild ? groups : groups.filter(g => !g.parentId);

        for (let group of orderBy(filteredGroups, 'position')) {
            if (!this.config.processedSyncIds.groups.includes(group.syncId)) {
                if (group.operator) {
                    humanReadable += `${lowerCase(dropdownUtils.constants.operators[group.operator])} `;
                }

                humanReadable += await this.humanReadableGroups(group.children, true);
                humanReadable += await this.humanReadableExpressions(group.expressions);
                this.config.processedSyncIds.groups.push(group.syncId);
            }
        }

        return humanReadable;
    },
    async humanReadableExpressions(expressions = []) {
        let humanReadable = '';

        for (let expression of orderBy(expressions, 'position')) {
            if (!this.config.processedSyncIds.expressions.includes(expression.syncId)) {
                if (expression.operator) {
                    humanReadable += `${lowerCase(dropdownUtils.constants.operators[expression.operator])} `;
                }

                humanReadable += await this.humanReadableComponents(expression.components);
                this.config.processedSyncIds.expressions.push(expression.syncId);
            }
        }

        return humanReadable;
    },
    async humanReadableComponents(components = [], isChild = false) {
        let humanReadable = '',
            filteredComponents = isChild ? components : components.filter(c => !c.parentId);

        for (let component of orderBy(filteredComponents, 'position')) {
            if (!this.config.processedSyncIds.components.includes(component.syncId)) {
                if (component.children.length) {
                    humanReadable += await this.humanReadableComponents(component.children, true);
                }

                humanReadable += ` ${this.config.componentType[component.componentType].humanReadable(component.data)}`;

                if (!isChild) {
                    this.config.processedSyncIds.components.push(component.syncId);
                }
            }
        }

        this.config.processedSyncIds.reset();

        return humanReadable;
    },
};

export function generateId(name = 'new') {
    // Generate a unique temporary id for a new object.
    return `${name}${uniqid()}`;
}

export function getId(obj) {
    // Fetch the object's id for saving, or null if it is a temporary id for a new object.
    let id = obj.id;

    if (/^new.*/.exec(id)) {
        id = undefined;
    }

    return id;
}

export function removeNestedComponents(data = {}) {
    data.expressionGroups.forEach(eGroup => {
        eGroup.expressions.forEach(e => {
            remove(e.components, c => c.parentId !== null);
        });
    });

    return data;
}

export function transformApiData(data) {
    let options = {};

    for (let item of data) {
        let sortedVersions = vueVersioning.methods.sortVersions(item);
        if (sortedVersions.length) {
            if (sortedVersions[0].classCodeId) {
                options[sortedVersions[0].code || item.id] = `${sortedVersions[0].title}`;
            } else {
                options[item.number || item.id] = item.title || `${item.number} ${sortedVersions[0].screenName}`;
            }
        }
    }

    return options;
}

export var dropdownUtils = {
    actionTypes() {
        return dropdownUtils.buildSelectOptions(this.tooltipWording.actionTypeOrder, this.tooltipWording.actionTypes);
    },
    buildSelectOptions(order, options) {
        return order?.map(option => {
            return {
                label: options[option],
                value: option,
            };
        });
    },
    constants: {
        componentTypes: {
            condition: 'Condition',
            literal: 'Literal Value',
            method: 'Method',
            object: 'Object',
            operator: 'Operator',
        },
        conditions: {
            contains: 'Contains',
            equals: 'Is equal to',
            not_equals: 'Is not equal to',
            greater_than: 'Is greater than',
            greater_than_equal: 'Is greater than or equal to',
            less_than: 'Is less than',
            less_than_equal: 'Is less than or equal to',
            exists: 'Exists',
            is_blank: 'Is left blank',
        },
        comparisonTypes: {
            numeric: 'Numeric',
            string: 'String',
            boolean: 'Boolean',
            date: 'Date'
        },
        dateConditions: {
            date_is_same: 'Is the same date as',
            date_is_not_same: 'Is not the same date as',
            date_is_after: 'Is after',
            date_is_same_or_after: 'Is the same date or after',
            date_is_before: 'Is before',
            date_is_same_or_before: 'Is the same date or before',
            date_is_more_than_units_after: 'Is more than ... after',
            date_is_less_than_units_after: 'Is less than ... after',
            date_is_more_than_units_before: 'Is more than ... before',
            date_is_less_than_units_before: 'Is less than ... before',
        },
        literalTypes: {
            single: 'Single Value',
            list: 'List of Values',
            date: 'Date',
        },
        matchTypes: {
            'every': 'Every',
            'any': 'Any',
        },
        mathematicalMethodTypes: {
            add: 'Plus',
            divide: 'Divided By',
            multiply: 'Multiplied By',
            subtract: 'Minus',
        },
        methodTypes: {
            math: 'Mathematical Method',
            count: 'Number of Objects',
            total: 'Total Value of Objects',
            length: 'Length',
        },
        operators: {
            and: 'AND',
            and_not: 'AND NOT',
            or: 'OR',
            or_not: 'OR NOT',
        },
        unitTypes: {
            days: 'days',
            years: 'years',
        },
        versionOptions: {
            current_version: 'Current Version',
            previous_version: 'Previous Version',
        },
    },
    findOptions(value, options) {
        if (value === null || value === undefined) {
            return [];
        } else if (!Array.isArray(value)) {
            value = value.split(',');
        }

        return value.map(val => {
            return options.find(option => {
                return option.value === val;
            });
        });
    },
    pageSelectLabel() {
        return this.pageType === 'class_code' ? 'Class Code' :
            this.pageType.charAt(0).toUpperCase() + this.pageType.slice(1);
    },
    pageSelectPlaceholder() {
        return `Select a ${this.pageType === 'class_code' ? 'class code' : this.pageType}...`;
    },
    pageTypes() {
        return dropdownUtils.buildSelectOptions(this.tooltipWording.pageTypeOrder, this.tooltipWording.pageTypes);
    },
    ruleTypes() {
        return dropdownUtils.buildSelectOptions(this.tooltipWording.ruleTypeOrder, this.tooltipWording.ruleTypes);
    },
    statusTypes() {
        return dropdownUtils.buildSelectOptions(this.tooltipWording.versioningOrder, this.tooltipWording.versioning);
    },
    transactionTypes() {
        return dropdownUtils.buildSelectOptions(this.tooltipWording.transactionTypeOrder, this.tooltipWording.transactionTypes);
    },
    yesNoOptions() {
        return [
            {
                label: 'Yes',
                value: true,
            },
            {
                label: 'No',
                value: false,
            },
        ];
    },
};
